import React from "react";
import Button from "../Button/Button";
import "./Role.scss";

const Role = ({ title, description, poster, link }) => (
  <div className="role-container">
    <h2 className="role-title">{title}</h2>
    <img alt="team-img" className="team-img" src={poster} />
    <p className="role-description">{description}</p>
    <Button dark route={link} label="Find out more" />
  </div>
);

export default Role;
